/* eslint-disable react/prop-types */
import { useState, ReactNode, useEffect } from 'react'
import { FormGroup, SingleDatePicker, filterProps } from '@jsluna/react'
import moment from 'moment'
import 'react-dates/initialize'
import 'react-dates/constants'
import { Label } from '@jsluna/form'
import { IPicker } from '../../../../../types/ComponentTypes/Components'
import { useComponentContext } from '../../ComponentContext'
moment.locale('en_GB')

function DateTimePickerComponent({
  label,
  id,
  error,
  placeholder,
  pickerType,
  calendarType,
  answer,
  defaultValue
}: IPicker) {
  const { handleUpdate } = useComponentContext()
  const [date, setDate] = useState<moment.Moment | null>(null)
  const [focused, setFocused] = useState(false)

  const updateDate = () => {
    if (defaultValue && !answer) {
      answer = defaultValue
    }
    if (answer) {
      setDate(moment(answer, 'YYYY-MM-DD'))
    }
  }

  useEffect(() => {
    updateDate()
    if (defaultValue) {
      handleUpdate({ id, value: defaultValue })
    }
  }, [])

  const handleTimeChange = (e: any) => {
    handleUpdate({ id, value: e?.target?.value })
  }

  const handleDateChange = (date: moment.Moment) => {
    setDate(date)
    handleUpdate({ id, value: date })
  }

  return (
    <div>
      {label && <Label htmlFor="">{label}</Label>}
      {pickerType === 'date' && (
        <FormGroup id="time-picker" error={error}>
          {({ id, ...inputProps }: { [key: string]: any }): ReactNode => (
            <SingleDatePicker
              id={id}
              placeholder={placeholder}
              {...filterProps(inputProps, ['aria-describedby', 'invalid'])}
              label={label}
              date={date}
              value={answer || ''}
              onDateChange={handleDateChange}
              focused={focused}
              displayFormat="DD/MM/YYYY"
              onFocusChange={({ focused }: { focused: boolean }) =>
                setFocused(focused)
              }
              isOutsideRange={
                calendarType === 'presentPast'
                  ? (day: moment.Moment) =>
                      day.isBefore(moment().subtract(1, 'year')) ||
                      day.isAfter(moment().add(12, 'hour'))
                  : calendarType === 'presentFuture'
                    ? (day: moment.Moment) =>
                        day.isAfter(moment().add(1, 'year')) ||
                        day.isBefore(moment().subtract(1, 'day'))
                  : calendarType === 'presentFutureMin'
                    ? (day: moment.Moment) =>
                        day.isAfter(moment().add(1, 'year')) ||
                        day.isBefore(moment().add(3, 'day'))
                  : null
              }
              block
            />
          )}
        </FormGroup>
      )}

      {pickerType === 'time' && (
        <FormGroup id="time-picker" error={error}>
          {({ id, ...inputProps }: { [key: string]: any }): ReactNode => (
            <input
              className="ln-c-text-input"
              {...filterProps(inputProps, ['aria-describedby', 'invalid'])}
              id={id}
              type="time"
              value={answer || ''}
              onChange={handleTimeChange}
            />
          )}
        </FormGroup>
      )}
    </div>
  )
}

export default DateTimePickerComponent
