import webApi from './webApi'
import { ApiClient } from './ApiClient'
import { IFeatureData } from '../types'

const GetFeatures = async (apiClient: ApiClient): Promise<string[]> => {
  const response = await webApi.get<string[]>(apiClient, {
    url: `/api/features`,
    params: []
  })
  return response
}

const GetFeature = async (apiClient: ApiClient, featureName: string, storeCode: string): Promise<IFeatureData> => 
{
  const response = await webApi.get<IFeatureData>(apiClient, {
    url: `/api/features/${featureName}/${storeCode}`,
    params: {featureName : featureName, storeCode: storeCode}
  })
  return response
}

const featureApi = {
  GetFeatures,
  GetFeature
}

export default featureApi