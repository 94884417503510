import { ApiClient } from "../api/ApiClient"
import featureApi from "../api/featureApi"

const getFeatureData = async (apiClient: ApiClient, featureName: string, storeCode: string) => {
      const feature = await featureApi.GetFeature(apiClient, featureName, storeCode)
  
      if (!feature.enabled)
      {
        return false
      }
      
      return true
  }

export default getFeatureData