const resetData = (context: any) => {
  context.page = {
    IncidentsPage: null,
    DraftsPage: null
  }
  sessionStorage.removeItem('IncidentsPage')
  sessionStorage.removeItem('DraftsPage')
}

export default resetData
